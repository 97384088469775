import { connect } from 'react-redux';
import { cartAction, wishlistAction, breadCrumbAction, modalAction } from '../../../../actions';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getFrom } from '../utilities';
import Skeleton from 'react-loading-skeleton';
import { BASE_URL } from '../../../../constants';


const PostOrderList = ({ modalAction, globalData, compCode, compInfo, modals }) => {

    const { id } = useParams(); 
    const history = useHistory(); 

    const handleClose = () => {
        history.push('/')
        modalAction('TABLE_SELECTION_MODAL', true);
    }   

    const [billData, setBillData] = useState({loading: false, data: {SalesObj: { SalesDetails: []}}, err: {status: false, msg: ''}})

    useEffect(() => {
        const getAreaResult = async () => {                      
            if (!compCode) return alert('no companyCode received');                  
            const res = await getFrom(`${BASE_URL}/api/pharma/GetKOTPrint?CID=${compCode}&LOCID=${globalData.location.LocationId}&BillId=${id}&Type=LAST`, {}, setBillData);
            if (res) setBillData(res);                                                   
            else alert('Something went wrong !!');
        }  
        getAreaResult();
    }, [id, compCode, globalData.location.LocationId])

    const data = billData.data.SalesObj;

    return (
        <div id="target" className='' style={{fontFamily: 'Lato'}}>
            <style>
                {`
                    .app-container > *:not(.app-content) {				
                        display: none;
                    }

                    .offcanvas-body {
                        padding: 10px 0 0;
                    }
                    #target {
                        margin: 0 auto;
                        background: #FFF;
                        font-family: Arial, Helvetica, sans-serif;
                    }
                    p {
                        font-size: 1.95rem;
                        padding: 0.6em 0.3em;
                        margin-bottom: 0;
                    }
                       
                    @page {
                        margin: 0 1%;
                    }
                    @media print {
                        .btnPrint {display: none;}
                    }
                    
                    @media (max-width: 767px) {
                        html {
                            font-size: 124% !important;
                        } 
                    }
                `}
            </style>

            <div id="invoice-POS" style={{padding: '1px', paddingRight: '1px 0.7em', background: '#FFF', fontFamily: 'Arial, Helvetica, sans-serif'}}>
                <center id="top">
                    <div className="btnPrint mb-2" style={{textAlign: 'center'}}>
                        <span className='btn btn-secondary px-2 py-1 me-3' onClick={() => window.print()}><i className='bx bxs-printer'></i> Print</span>
                        <Link to={'/'} onClick={handleClose} className='btn btn-secondary px-2 py-1'><i className='bx bx-x'></i> Close</Link>
                    </div>
                    <div className="logo" />
                    <div className="info">
                        <h6 style={{marginBottom: 0, marginTop: 0}}>
                            <font style={{fontSize: '2.3rem', fontWeight: 'bold'}}> {compInfo.COMPNAME}</font>
                        </h6>
                    </div>
                </center>
                <div id="mid" style={{borderBottom: '1px solid #000'}}>
                    <div className="info">
                        <h2 style={{textAlign: 'center', fontSize: '1.4em'}} className='my-2 fw-bold'>Order List</h2>
                        <table style={{width: '100%', fontSize: '1.4em'}}>
                            <tbody>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Order #</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {data.VchNo}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Order Date</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {new Date(data.VchDate).toLocaleDateString('en-TT')} &nbsp;&nbsp; {new Date().getHours()} : {new Date().getMinutes()}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Table No</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {data.BedDesc}</td>
                                </tr>
                                {data.CollectedBy && <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Waiter Name</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {data.CollectedBy}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="bot">
                    <div id="table">
                        <table className='w-100' style={{fontSize: '1.6em'}}>
                            <tbody>
                                <tr className="tabletitle" style={{borderBottom: '1px solid #000'}}>
                                    <td style={{verticalAlign: 'top', fontWeight: 'bold'}}>#</td>
                                    <td style={{verticalAlign: 'top', fontWeight: 'bold'}} className="item">Particulars</td>
                                    <td style={{verticalAlign: 'top', fontWeight: 'bold'}} className="Hours" align="right">Qty</td>
                                </tr>
                                {billData.loading ? <tr className="tabletitle" style={{borderBottom: '1px solid #000'}}>
                                    <td colSpan={3}>
                                        <Skeleton count={5} />
                                    </td>
                                </tr> : ''}
                                {data.SalesDetails?.map((i, n) => (
                                    <tr className="service" style={{borderBottom: '1px solid #000'}} key={n}>
                                        <td className="tableitem" style={{verticalAlign: 'top', width: '8%'}}><p className="itemtext" style={{fontWeight: 'bold'}}>{n+1}.</p></td>
                                        <td className="tableitem" style={{verticalAlign: 'top'}}>
                                            <p className="itemtext" style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                                                {i.Description}
                                                <span className='fw-normal d-block' style={{marginTop: '0.6em', fontSize: '0.6em'}}>{i.Specification}</span>
                                            </p>
                                        </td>
                                        <td className="tableitem" align="right" style={{verticalAlign: 'top'}}>
                                            <p className="itemtext" style={{fontWeight: 'bold'}}>
                                                {i.BillQty}
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div id="legalcopy">
                        <div className="info" style={{textAlign: 'center'}}>
                        </div>
                    </div>
                    <div className="btnPrint mt-2" style={{textAlign: 'center'}}>
                        <span className='btn btn-secondary px-2 py-1 me-3' onClick={() => window.print()}><i className='bx bxs-printer'></i> Print</span>
                        <Link to={'/'} onClick={handleClose} className='btn btn-secondary px-2 py-1'><i className='bx bx-x'></i> Close</Link>
                    </div>
                </div>
            </div>

            {/* <div id="invoice-POS" style={{padding: '1px', paddingRight: '1px 0.7em', background: '#FFF', fontFamily: 'Arial, Helvetica, sans-serif'}}>
                <center id="top">
                    <div className="btnPrint mb-2" style={{textAlign: 'center'}}>
                        <span className='btn btn-secondary px-2 py-1 me-3' onClick={() => window.print()}><i className='bx bxs-printer'></i> Print</span>
                        <Link to={'/'} onClick={handleClose} className='btn btn-secondary px-2 py-1'><i className='bx bx-x'></i> Close</Link>
                    </div>
                    <div className="logo" />
                    <div className="info">
                        <h6 style={{marginBottom: 0, marginTop: 0}}>
                            <font style={{fontSize: '2.3rem', fontWeight: 'bold'}}> {compInfo.COMPNAME}</font>
                        </h6>
                    </div>
                </center>
                <div id="mid" style={{borderBottom: '1px solid #000'}}>
                    <div className="info">
                        <h2 style={{textAlign: 'center', fontSize: '1.4em'}} className='my-2 fw-bold'>Order List</h2>
                        <table style={{width: '100%', fontSize: '1.4em'}}>
                            <tbody>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Order #</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {modalData.orderNo}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Order Date</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {new Date().toLocaleDateString('en-TT')} &nbsp;&nbsp; {new Date().getHours()} : {new Date().getMinutes()}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Table No</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {modalData.tableNo}</td>
                                </tr>
                                {modalData.waiterName && <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Waiter Name</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {modalData.waiterName}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="bot">
                    <div id="table">
                        <table className='w-100' style={{fontSize: '1.6em'}}>
                        <tbody><tr className="tabletitle" style={{borderBottom: '1px solid #000'}}>
                            <td style={{verticalAlign: 'top', fontWeight: 'bold'}}>#</td>
                            <td style={{verticalAlign: 'top', fontWeight: 'bold'}} className="item">Particulars</td>
                            <td style={{verticalAlign: 'top', fontWeight: 'bold'}} className="Hours" align="right">Qty</td>
                            </tr>
                            {modalData.orderItems?.map((i, n) => (
                                <tr className="service" style={{borderBottom: '1px solid #000'}} key={n}>
                                    <td className="tableitem" style={{verticalAlign: 'top', width: '8%'}}><p className="itemtext" style={{fontWeight: 'bold'}}>{n+1}.</p></td>
                                    <td className="tableitem" style={{verticalAlign: 'top'}}>
                                        <p className="itemtext" style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                                            {i.Description}
                                        </p>
                                    </td>
                                    <td className="tableitem" align="right" style={{verticalAlign: 'top'}}>
                                        <p className="itemtext" style={{fontWeight: 'bold'}}>
                                            {i.BillQty}
                                        </p>
                                    </td>
                                </tr>
                            ))}
                        </tbody></table>
                    </div>
                    <div id="legalcopy">
                        <div className="info" style={{textAlign: 'center'}}>
                        </div>
                    </div>
                    <div className="btnPrint mt-2" style={{textAlign: 'center'}}>
                        <span className='btn btn-secondary px-2 py-1 me-3' onClick={() => window.print()}><i className='bx bxs-printer'></i> Print</span>
                        <Link to={'/'} onClick={handleClose} className='btn btn-secondary px-2 py-1'><i className='bx bx-x'></i> Close</Link>
                    </div>
                </div>
            </div> */}
        </div>

    )  
}

const mapStateToPropsTwo = (state) => {
  return { cart: state.cart, compCode: state.compCode, isMobile: state.isMobile, globalData: state.globalData, userInfo: state.userInfo, compInfo: state.compInfo, isLoggedIn: state.isLoggedIn, modals: state.modals };
}

export default connect(mapStateToPropsTwo, {breadCrumbAction, cartAction, wishlistAction, modalAction})(PostOrderList);