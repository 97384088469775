import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
// import history from '../history.js';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import { Spinner, focusArea, getFallbackImg, getFrom, mmDDyyyyDate, noticeToast, productToast, updateLocalStorageItems } from '../utilities';	
import { loaderAction, breadCrumbAction, cartAction, wishlistAction, modalAction, globalDataAction } from '../../../../actions';
import { ConnectedProductCard, ConnectedProductCard1 } from './../cards';
import SliderSection from '../sliderSection';
import { SliderM } from '../mobileView/homeM';
import { BASE_URL, ePharmaId, RAJE_RESTAURANT_ID, TAKE_HOME_ID, XYZ_ID } from '../../../../constants';
import Reviews from './reviews';

const ProductPage = ({ loaderAction, match, breadCrumbAction, compCode, cartAction, isMobile, cart, wishlistAction, wishlist, modalAction, globalData, globalDataAction }) => {

	const [venoBoxSliderImageList, setVenoBoxSliderImageList] = useState({loading: false, data: {ImageMasterCollection: [], ItemMaster: {}, itemMasterCollection: []}, err: {status: false, msg: ''}});       // ItemMaster contains the full Detail of the item.
	const [counter, setCounter] = useState(1);
	const [tabActive, setTabActive] = useState('description');
	const [activePackSize, setPackSize] = useState('');
	
	useEffect(() => {										   
		window.render_ProductPageSlider();
		window.renderVenoBox();
		setCounter(1);
	},[venoBoxSliderImageList])
	
	useEffect(() => {
		breadCrumbAction({links: [{name: 'Home', link: '/'}, {name: 'Product Details', link: '/filterPage'}], activeLink: '/filterPage'});
	},[breadCrumbAction])
	
	useEffect(() => {
		async function getSliderItemImges() {
			loaderAction(true);
			const res = await getFrom(`${BASE_URL}/api/Pharma?CID=${compCode}&PID=${match.params.id}&LOCID=${globalData.location.LocationId}`, {}, setVenoBoxSliderImageList);
			if (res) {
				setVenoBoxSliderImageList(res); 
			} else {
				console.log('No data received');
			}
			loaderAction(false);
		}
		getSliderItemImges();
	},[match.params.id, loaderAction, compCode, globalData.location.LocationId])

	useEffect(() => {
		const packSizeList = venoBoxSliderImageList.data.ItemMaster?.ItemPackSizeList;
		if (packSizeList && packSizeList?.length) {
			const firstSizeId = packSizeList[0];
			setPackSize(firstSizeId);
		} else {
			setPackSize('');
		}
	},[venoBoxSliderImageList.data.ItemMaster])
	
	const isAddedToCart = Object.values(cart.pharmacy).filter(i => i.ItemId === venoBoxSliderImageList.data.ItemMaster.ItemId).length;
	const isAddedToWishlist = Object.values(wishlist.pharmacy).filter(i => i.ItemId === venoBoxSliderImageList.data.ItemMaster.ItemId).length;

	const renderVenoboxSlider = (data) => {

		if (data.loading) {
			return <Spinner min_height='50rem' fSize='2.5rem'/>;
		} else if (data.err.status) {
			return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
	    } else if (data.data.ImageMasterCollection.length === 0) {
			return (
				<div className="lg-image img-zoom-container">
					<a className="popup-img venobox vbox-item d-flex justify-content-center" href={`#`} data-gall="myGallery">
						<img className="myimage img-fluid" src={getFallbackImg()} alt="product" height="450" width="auto"/>
					</a>
				</div>
			)
			// <div className='text-center my-5 w-100'><h2 className="text-warning">No Image Found.</h2></div>;
		} else {
			return (   
				<div>   {/* never try to replace div with <> */}                                                   
					<div className="product-details-images slider-navigation-1">  			{/* component from parent div hence rendering slider and spinner both togather and use css to show/hide the spinner */}  
						{											              
							data.data.ImageMasterCollection.map((item, index) => 			{            // When looping on each slider image, slick slider adds a div to parent div, during rerendering react recognizes it as non-child hence instead removing,
								return (										 // it throws non-child removal error (attempt to remove child that is not child of the parent). hence we'll replace the whole parent div to overcome this issue.
									<div className="lg-image img-zoom-container" key={index}>
										<a className="popup-img venobox vbox-item d-flex justify-content-center" href={item.ImgURL} data-gall="myGallery">
											<img id={item.ImgId} className="myimage img-fluid" src={item.ImgURL} style={{maxHeight: '451px'}} alt="product" height="450" width="auto"/>
										</a>
									</div>
								)
							})
						}
					</div>
					
					<div className="product-details-thumbs slider-thumbs-1" style={{paddingTop:'3%'}}>  								  {/* component from parent div hence rendering slider and spinner both togather and using css to show/hide the spinner */}  
						{											              
							data.data.ImageMasterCollection.map((item, index) => 			{            // When looping on each slider image, slick slider adds a div to parent div, during rerendering react recognizes it as non-child hence instead removing,
								return (										 // it throws non-child removal error (attempt to remove child that is not child of the parent). hence we'll replace the whole parent div to overcome this issue.
									<div key={index} className="sm-image" style={{paddingLeft:'5px', paddingRight:'5px', width: 'auto'}}><img src={item.ImgURL} style={{maxHeight: '100px'}} alt="product thumb" /></div>
								)
							})
						}
					</div>
				</div>
			)
		}
	}

	const renderProductSlider = useMemo(() => {              		// Connecting cartlist in redux state is cousing this function to be recalculated when we click add to cart button which adds an item in cartlist, this results reRendering of carousel. 
		// console.log(data);									    // To prevent this we using useMemo, forcing it to rerender only if venoBoxSliderImageList changes not when cartlist changes. text on card buttons is updated from card component.
		if (venoBoxSliderImageList.loading) {						// We don't need to reRender whole carousel to update that.
		  return <Spinner min_height='25rem' fSize='1.5rem'/>;
		} else if (venoBoxSliderImageList.err.status) {
		  return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{venoBoxSliderImageList.err.msg}</span></h2></div>;
		} else if (venoBoxSliderImageList.data.itemMasterCollection.length === 0) {
		  return false; // <div className='text-center my-5 w-100'><h2 className="text-info"><span className="text-warning">No Related Products Found.</span></h2></div>;
		} else {
			if (isMobile) return <SliderM data={venoBoxSliderImageList.data.itemMasterCollection}/>;
			return (
				<div className='specialistCard-box'>
					{(() => {
                        if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
                          return <SliderSection children={<ConnectedProductCard1 />} data={venoBoxSliderImageList.data.itemMasterCollection} id="neurology-slider" heading={'Neurology'} />;
                        } else {
                          return <SliderSection children={<ConnectedProductCard />} data={venoBoxSliderImageList.data.itemMasterCollection} id="neurology-slider" heading={'Neurology'} />;
                        }
                      })()}
				</div>
			)
		}
	},[venoBoxSliderImageList, isMobile])
	
	const handleDummyFunction = () => false;

	let bestBeforeDate = venoBoxSliderImageList.data.ItemMaster?.EXPDate;
		bestBeforeDate = mmDDyyyyDate(bestBeforeDate, '/', '/');																		// convert dd/mm/yyyy to mm/dd/yyyy.
	let bestBeforeDateString = bestBeforeDate ? new Date(bestBeforeDate).toDateString().split(' ').slice(1, 4).join(' ') : '';

	const computeWithPackSize = () => {
		if (!activePackSize) {
			return { ItemMRP: venoBoxSliderImageList.data.ItemMaster?.ItemMRP, SRate: venoBoxSliderImageList.data.ItemMaster?.SRate, StockQty: venoBoxSliderImageList.data.ItemMaster?.StockQty, DiscountPer: venoBoxSliderImageList.data.ItemMaster?.DiscountPer, PackSizeId: venoBoxSliderImageList.data.ItemMaster?.PackSizeId };
		} else {
			if (activePackSize.MRP) { 
				return { ItemMRP: activePackSize.MRP, SRate: activePackSize.SRate, StockQty: activePackSize.StockQty, DiscountPer: activePackSize.MRPDisPer, PackSizeId: activePackSize.CodeId };	  
			} else {
				return { ItemMRP: venoBoxSliderImageList.data.ItemMaster?.ItemMRP, SRate: venoBoxSliderImageList.data.ItemMaster?.SRate, StockQty: venoBoxSliderImageList.data.ItemMaster?.StockQty, DiscountPer: venoBoxSliderImageList.data.ItemMaster?.DiscountPer, PackSizeId: venoBoxSliderImageList.data.ItemMaster?.PackSizeId };
			}
		}
	}

    const handlePackSize = (i) => {
		if (i.CodeId === computeWithPackSize().PackSizeId) return;
		setPackSize(i);
	}

	const handleAdd = () => {
		if (!globalData.location.LocationId) return focusArea(globalDataAction);
		if (isAddedToCart) {
			cartAction('REMOVE_ITEM', venoBoxSliderImageList.data.ItemMaster.ItemId, 'pharmacy');
		} else {
			cartAction('ADD_ITEM', {...venoBoxSliderImageList.data.ItemMaster, count: counter, ...computeWithPackSize()}, 'pharmacy'); 
			wishlistAction('REMOVE_WISH_ITEM', venoBoxSliderImageList.data.ItemMaster.ItemId, 'pharmacy');
			let productToastData = { msg: 'Added to Cart', product: {name: venoBoxSliderImageList.data.ItemMaster.Description, price: computeWithPackSize().SRate}, button: {text: 'Visit Cart', link: '/cartPage'} };
			productToast(productToastData);
		}
		updateLocalStorageItems()
    }

  	return (
		<div className='pt-3 epharma-global'>
			<div className="content-wraper">
				<div className="container">
					<div className="row single-product-area">
						<div className="col-lg-5 col-md-6">
							<div className="product-details-left position-relative" style={{overflow: 'hidden'}}>   {/** maxHeight: '580px' */}
								{renderVenoboxSlider(venoBoxSliderImageList)}
							</div>
						</div>

						<div className="col-lg-7 col-md-6">
							<div id="myresult" className="img-zoom-result" style={{display:'none'}}>

							</div>
							<div className="product-details-view-content pt-30">
								<div className="product-info">
									<h2>{venoBoxSliderImageList.data.ItemMaster?.Description}</h2>
									{venoBoxSliderImageList.data.ItemMaster?.ItemPackSizeList?.length ? <div className="product-variants pack-sizes-box pb-20 pb-xs-10" style={{borderBottom: '1px solid #e1e1e1'}}>
										<div className="produt-variants-size">
											<label>Pack size: </label>
											<div className='pack-sizes'>
												{venoBoxSliderImageList.data.ItemMaster?.ItemPackSizeList?.map((i, n) => (
													<div key={i.CodeId} className={i.CodeId === computeWithPackSize().PackSizeId ? 'active' : ''} onClick={() => handlePackSize(i)}>
														<h5>{i.Description}</h5>
														<div className='d-flex gap-2'>
															<p className="old-price">₹ {i.MRP ? i.MRP : venoBoxSliderImageList.data.ItemMaster?.ItemMRP}</p>
															<p>₹ {i.SRate ? i.SRate : venoBoxSliderImageList.data.ItemMaster?.SRate}</p>															
														</div>
													</div>
												))}
											</div>
										</div>
									</div> : ''}
									{compCode === TAKE_HOME_ID ? '' : <div className="rating-box" style={{fontSize: '1em'}}>
										<ul className="rating rating-with-review-item">
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star-half-alt"></i></li>
											<li className="review-item mx-4"><Link to="#"><i className="fas fa-th-list"></i> Read Review</Link></li>
											<li className="review-item"><Link to="#"><i className="fas fa-pencil-alt"></i> Write Review</Link></li>
										</ul>
									</div>}
									{globalData.location.LocationId ? <div className="price-box pt-3">
										<span className="old-price">₹ {computeWithPackSize().ItemMRP}</span>
										<span className="new-price">₹ {computeWithPackSize().SRate}</span>
										<span className="discount-percentage" style={{paddingLeft:'15px', color:'green'}}>{computeWithPackSize().DiscountPer}% OFF</span>
									</div> : ''}
									<div className="product-desc mb-0">
										<p> Best before:<span> {bestBeforeDateString}</span>
										</p>
										<p> Generic name: <span> {venoBoxSliderImageList.data.ItemMaster?.Technicalname}</span>
										</p>
										<p> MFG By: <span> {venoBoxSliderImageList.data.ItemMaster?.ManufacturBY}</span>
										</p>
									</div>
									
									<div className="single-add-to-cart">
										<form action="#" className="cart-quantity d-flex flex-wrap">
											<div className="quantity d-flex gap-4 align-items-center">
												<label>Quantity</label>
												<div className="cart-plus-minus">
													<input onChange={handleDummyFunction} className="cart-plus-minus-box" value={counter} type="text"/>
													<div className="dec qtybutton" onClick={() => {if (counter !== 1) setCounter(counter-1)}}><i className="fa fa-angle-down"></i></div>
													<div className="inc qtybutton"><i onClick={() => setCounter(counter+1)} className="fa fa-angle-up"></i></div>
												</div>
											</div>
											{(() => {
												if (compCode === TAKE_HOME_ID|| compCode === ePharmaId) {
													return (
														<>
															{venoBoxSliderImageList.data.ItemMaster?.Category !== 24856 ? <button className={`add-to-cart ${!globalData.location.LocationId || computeWithPackSize().StockQty ? '' : 'opacity-50 pe-none'}`} type="button" onClick={handleAdd}>{isAddedToCart === 1 ? 'Remove from cart' : 'Add to cart'}</button> : 
															<button className={`add-to-cart`} type="button" onClick={() => noticeToast({title: 'Over Counter Sales only..', msg: 'As Government Norms this Product is not to be sold Online - Contact with Service Provider for buying this product.'}, { position: "top-center", autoClose: 5000 })}>Counter Sale only</button>}
														</>
													)
												} else {
													return <button className={`add-to-cart ${!globalData.location.LocationId || computeWithPackSize().StockQty ? '' : ''}`} type="button" onClick={handleAdd}>{isAddedToCart === 1 ? 'Remove from cart' : 'Add to cart'}</button>;
												}
											})()}
											
										</form>																		{/*	three dot's means we taking all properties from venoBoxSliderImageList object and place them in this new object along with new count property */}
									</div>
									<div className="product-additional-info pt-25 pt-xs-15">
										<div className='d-flex gap-5 flex-wrap'>
											<h6 className={`a wishlist-btn`} onClick={() => {cartAction('REMOVE_ITEM', venoBoxSliderImageList.data.ItemMaster.ItemId, 'pharmacy'); wishlistAction('ADD_WISH_ITEM', {...venoBoxSliderImageList.data.ItemMaster, count: counter, ...computeWithPackSize()}, 'pharmacy'); updateLocalStorageItems()}}><i className={`fa${isAddedToWishlist ? 's' : 'r'} fa-heart`}></i>{isAddedToWishlist === 1 ? 'Added' : 'Add'} to wishlist</h6>
											{globalData.location.LocationId && !computeWithPackSize().StockQty ? <h6 className={`a wishlist-btn text-danger`}><i className={`fa fa-window-close`}></i>Out of Stock</h6> : ''}
										</div>
										{/* {compCode === TAKE_HOME_ID ? '' : <div className="product-social-sharing pt-25">
											<ul>
												<li className="facebook"><Link to="#"><i className="fa fa-facebook"></i>Facebook</Link></li>
												<li className="twitter"><Link to="#"><i className="fa fa-twitter"></i>Twitter</Link></li>
												<li className="google-plus"><Link to="#"><i className="fa fa-google-plus"></i>Google +</Link></li>
												<li className="instagram"><Link to="#"><i className="fa fa-instagram"></i>Instagram</Link></li>
											</ul>
										</div>} */}
									</div>
									<div className="accordion accordion-flush block-reassurance pt-25 pt-xs-15" id="reassurance-accordion">
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingDefaultOne">
											<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDefaultOne" aria-expanded="true" aria-controls="collapseDefaultOne">
												<i className="fas fa-exchange-alt"></i> Return policy
											</button>
											</h2>
											<div id="collapseDefaultOne" className="accordion-collapse collapse" aria-labelledby="headingDefaultOne" data-bs-parent="#reassurance-accordion">
												<div className="accordion-body">
													<div className="categories-box position-relative">
														<div className="card mb-4">
															<div className="card-header d-flex justify-content-between align-items-baseline p-4">
																<h5 className="mb-0">RETURN PROCESS:</h5>
															</div>
															<div className="card-body cart">
																<ol className='mb-0'>
																	<li>For Return intimation, please visit <Link onClick={() => modalAction('RETURN_POLICY_MODAL', false)} to="/contactUs">www.takehomemedicine.in/#/contactUs</Link>.</li>
																	<li>TakeHome customer care team will verify the claim made by the customer within 72 (seventy-two) business hours from the time of receipt of complaint.</li>
																	<li>Once the claim is verified as genuine and reasonable, TakeHome will initiate the collection of product(s) to be returned.</li>
																	<li>The customer will be required to pack the product(s) in original manufacturer’s packaging.</li>
																	<li>Refund will be completed within 30 (thirty) days from date of reverse pick up (if required).</li>
																</ol>
															</div>
															<div className="card-footer">
																<Link to="/returnPolicy" className="continue-button ms-auto d-table">Read More</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingDefaultTwo">
											<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDefaultTwo" aria-expanded="false" aria-controls="collapseDefaultTwo">
												<i className="fa fa-truck"></i> Shipping Details
											</button>
											</h2>
											<div id="collapseDefaultTwo" className="accordion-collapse collapse" aria-labelledby="headingDefaultTwo" data-bs-parent="#reassurance-accordion">
												<div className="accordion-body">
													<div className="categories-box position-relative">
														<div className="card mb-4">
															<div className="card-header d-flex justify-content-between align-items-baseline p-4">
																<h5 className="mb-0">SHIPPING CHARGES:</h5>
															</div>
															<div className="card-body cart">
																<p className='mb-0'>Estimated shipping charges are calculated as per the value of the order and can be viewed in the cart section at the time of checkout. For any further shipping related information, please write to hbkalyanipharmacy@gmail.com For any further Refund related information, please write to hbkalyanipharmacy@gmail.com</p>
															</div>
															<div className="card-footer">
																<Link to="/returnPolicy" className="continue-button ms-auto d-table">Read More</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="accordion-item">
											<h2 className="accordion-header" id="headingDefaultThree">
											<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDefaultThree" aria-expanded="false" aria-controls="collapseDefaultThree">
												<i className="fas fa-shield-alt"></i> Privacy Policy
											</button>
											</h2>
											<div id="collapseDefaultThree" className="accordion-collapse collapse" aria-labelledby="headingDefaultThree" data-bs-parent="#reassurance-accordion">
												<div className="accordion-body">
													<div className="categories-box position-relative">
														<div className="card mb-4">
															<div className="card-header d-flex justify-content-between align-items-baseline p-4">
																<h5 className="mb-0">Privacy Policy</h5>
															</div>
															<div className="card-body cart">
																<p>TakeHome collects Data to improve user experience. The data includes following categories:</p>
																<ol className='mb-0'>
																	<li>Contact information: first and last name, email address, postal address, country, employer, phone number and other similar contact data.</li>
																	<li>Financial information: payment instrument information, transactions, transaction history, preferences, method, mode and manner of payment, spending pattern or trends, and other similar data.</li>
																	<li>Technical information: website, device and mobile app usage, Internet Protocol (IP) address and similar information collected via automated means, such as cookies, pixels and similar technologies.</li>
																	<li>Transaction information: the date of the transaction, total amount, transaction history and preferences and related details.</li>
																	<li>Health related information, such as information or records relating to Your medical/ health history, health status, details of treatment plans and medication prescribed by a Medical Practitioner, dosage details such as frequency of dosage, alternative medication, medicines ordered by You through the Platform, laboratory testing results and any other information inferred there from</li>
																	<li>Product and service information: Your account membership number, registration and payment information, and program-specific information, when you request products and/or services directly from us, or participate in marketing programs.</li>
																	<li>Personal information: Age, sex, date of birth, marital status, nationality, details of government identification documents provided, occupation, ethnicity, religion, travel history or any other personal information provided in responses to surveys or questionnaires.</li>
																	<li>Your reviews, feedback and opinions about our products, programmes and services.</li>
																	<li>Loyalty programme information: your loyalty membership information, account details, profile or password details and any frequent flyer or travel partner programme affiliation.</li>
																</ol>
															</div>
															<div className="card-footer">
																<Link to="/privacyPolicy" className="continue-button ms-auto d-table">Read More</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="product-area pt-35 pt-xs-10">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="li-product-tab">
								<ul className="nav li-product-menu">
									<li className={`${tabActive === 'description' && 'active'}`} onClick={() => setTabActive('description')}>Description</li>
									<li className={`${tabActive === 'productDetails' && 'active'}`} onClick={() => setTabActive('productDetails')}>Product Details</li>
									{compCode === TAKE_HOME_ID ? '' : <li className={`${tabActive === 'reviews' && 'active'}`} onClick={() => setTabActive('reviews')}>Reviews</li>}
								</ul>
							</div>
						</div>
					</div>
					<div className="row">							      {/* Reviews page's design in reviews tab needs zero horozontal padding */}
						<div className={`tab-content overflow-hidden pt-2 ${tabActive === 'reviews' ? 'px-0' : ''}`}>     
							<div id="tabFade-pane-desc" className={`tab-pane fade ${tabActive === 'description' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-1">
								Coming soon...              
							</div>
							<div id="tabFade-pane-details" className={`tab-pane fade ${tabActive === 'productDetails' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-1">
								Coming soon...             
							</div>
							{compCode === TAKE_HOME_ID ? '' : <div id="tabFade-pane-reviews" className={`tab-pane fade ${tabActive === 'reviews' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-1">
								<Reviews tabActive={tabActive}/>
							</div> }
						</div>
					</div> 


					<div className="tab-content">
						{/* <div id="description" className="tab-pane active show" role="tabpanel">
							<div className="product-description">
								<span>Model Specification</span>
								<span>Model Technicalname</span>
							</div>
						</div> */}
						<div id="product-details" className="tab-pane" role="tabpanel">
							<div className="product-details-manufacturer">
								<Link to="#">
									<img src="/images/product-details/1.jpg" alt="Product Manufacturer"/>
								</Link>
								<p><span>Reference</span> demo_7</p>
								<p><span>Reference</span> demo_7</p>
							</div>
						</div>
						<div id="reviews" className="tab-pane" role="tabpanel">
							<div className="product-reviews" style={{display:'none'}}>
								<div className="product-details-comment-block">
									<div className="comment-review">
										<span>Grade</span>
										<ul className="rating">
											<li><i className="fa fa-star-o"></i></li>
											<li><i className="fa fa-star-o"></i></li>
											<li><i className="fa fa-star-o"></i></li>
											<li className="no-star"><i className="fa fa-star-o"></i></li>
											<li className="no-star"><i className="fa fa-star-o"></i></li>
										</ul>
									</div>
									<div className="comment-author-infos pt-25">
										<span>HTML 5</span>
										<em>01-12-18</em>
									</div>
									<div className="comment-details">
										<h4 className="title-block">Demo</h4>
										<p>Plaza</p>
									</div>
									<div className="review-btn">
										<Link className="review-links" to="#" data-toggle="modal" data-target="#mymodal">Write Your Review!</Link>
									</div>
									<div className="modal fade modal-wrapper" id="mymodal">
										<div className="modal-dialog modal-dialog-centered" role="document">
											<div className="modal-content">
												<div className="modal-body">
													<h3 className="review-page-title">Write Your Review</h3>
													<div className="modal-inner-area row">
														<div className="col-lg-6">
															<div className="li-review-product">
																<img src="@Model.ImageMasterCollection[0].ImgURL"  width="400" height="400" alt="Li's Product"/>
																<img src="/images/product/large-size/3.jpg" alt="Li's Product"/>
																<div className="li-review-product-desc">
																	<p className="li-product-name">Model GroupName</p>
																	<p>
																		<span>Beach Camera Exclusive Bundle - Includes Two Samsung Radiant 360 R3 Wi-Fi Bluetooth Speakers. Fill The Entire Room With Exquisite Sound via Ring Radiator Technology. Stream And Control R3 Speakers Wirelessly With Your Smartphone. Sophisticated, Modern Design </span>
																	</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="li-review-content">
																<div className="feedback-area">
																	<div className="feedback">
																		<h3 className="feedback-title">Our Feedback</h3>
																		<form action="#">
																			<p className="your-opinion">
																				<label>Your Rating</label>
																				<span>
																					<select className="star-rating">
																						<option value="1">1</option>
																						<option value="2">2</option>
																						<option value="3">3</option>
																						<option value="4">4</option>
																						<option value="5">5</option>
																					</select>
																				</span>
																			</p>
																			<p className="feedback-form">
																				<label htmlFor="feedback">Your Review</label>
																				<textarea id="feedback" name="comment" cols="45" rows="8" aria-required="true"></textarea>
																			</p>
																			<div className="feedback-input">
																				<p className="feedback-form-author">
																					<label htmlFor="author">
																						Name<span className="required">*</span>
																					</label>
																					<input onChange={handleDummyFunction} id="author" name="author" value="" size="30" aria-required="true" type="text"/>
																				</p>
																				<p className="feedback-form-author feedback-form-email">
																					<label htmlFor="email">
																						Email<span className="required">*</span>
																					</label>
																					<input onChange={handleDummyFunction} id="email" name="email" value="" size="30" aria-required="true" type="text"/>
																					<span className="required"><sub>*</sub> Required fields</span>
																				</p>
																				<div className="feedback-btn pb-15">
																					<Link to="#" className="close" data-dismiss="modal" aria-label="Close">Close</Link>
																					<Link to="#">Submit</Link>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{renderProductSlider && <section className="product-area li-laptop-product pt-30 pt-xs-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="li-section-title">
								<h2>
									<span>Related Products You May Like</span>
								</h2>
							</div>
							<div className="row">
								<div className="product-active">
									<div className="col-lg-12 home-item position-relative">
										{renderProductSlider}
									</div>                       
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>}
		</div>
	)
}

const mapStateToProps = (state) => {
  return { compCode: state.compCode, isMobile: state.isMobile, cart: state.cart, wishlist: state.wishlist, globalData: state.globalData };
}

export default connect(mapStateToProps, {loaderAction, breadCrumbAction, cartAction, modalAction, globalDataAction, wishlistAction})(ProductPage);