import { useEffect } from "react";
import { connect } from "react-redux";
import { decrypt, encrypt } from "./utilities";
import axios from "axios";
import { loginStatusAction, userInfoAction, compCodeAction, loaderAction, modalAction } from "../../../actions";
import { BASE_URL, hasTable } from "../../../constants";


const Authentication = ({ compCode, userInfo, bookingInfo, modalAction, loginStatusAction, userInfoAction, compCodeAction, loaderAction, history }) => {

  // Copy saved in test.jsx
  // const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });
    
  useEffect(() => {
    autoLogin(compCode);
  }, [compCode])

  function autoLogin(companyCode) {
    const savedLoginData = decrypt(localStorage.getItem('userLoginData'));
    if (companyCode !== '') {
      if (savedLoginData) {
        compareCompCodes(companyCode, savedLoginData); 
      } else {
        console.log('Prepare Fresh Login');
        handleNewUser()
      }
    } else {
      console.log('No root compcode found');
      saveAndLoginUser(savedLoginData);
    }
  }
  function compareCompCodes(currCompCode, savedLoginData) {               // defining it outside the useEffect without usecallback makes it not trigger on compcode changes. leaving user logged out. 
    if (currCompCode === savedLoginData.compCode) {
      console.log(currCompCode, savedLoginData.compCode);
      saveAndLoginUser(savedLoginData);
      return;
    }
    console.log('Company code Mismatch.');
    handleNewUser();
  }

  const saveAndLoginUser = (savedData) => {
    if (savedData && savedData.phone) {
      console.log('Logging you in! Please wait.');        
      makeLoginRequest({ phone: savedData.phone, password: savedData.password, companyCode: savedData.compCode });
    } else {
      handleNewUser();    
    }
  }

  const makeLoginRequest = async (params) => {
    loaderAction(true);
    const res = await axios.get(`${BASE_URL}/api/UserAuth?UN=${params.phone}&UP=${params.password}&CID=${params.companyCode}`);
    loaderAction(false);
    if (res.data.Remarks === 'INVALID') {
      console.log('The username or password is incorrect.');
      handleNewUser();
    } else if (res.data.Remarks === 'NOTINCOMPANY') {
      console.log('Not Registered in this company.')
      handleNewUser();
    } else {
      let userLoginData = {
          Name: res.data.UserFullName,
          RegMob1: params.phone,
          UserId: res.data.UserId,
          UserType: res.data.UserType,
          PartyCode: res.data.PartyCode,
          EncCompanyId: params.companyCode,
          Age: res.data.Age,
          AgeDay: res.data.AgeDay,
          AgeMonth: res.data.AgeMonth,
          Gender: res.data.Gender,
          GenderDesc: res.data.GenderDesc,
          MPartyCode: res.data.MPartyCode,
          Address: res.data.Address,
          Qualification: res.data.Qualification,
          SpecialistDesc: res.data.SpecialistDesc,
          RegNo: res.data.RegNo ? res.data.RegNo : '',
          State: res.data.State,                          
          City: res.data.City,
          Pin: res.data.Pin,
          Address2: res.data.Address2,
          UHID: res.data.UHID,
          MemberId: res.data.MemberId,
          PartyId: res.data.PartyId,
          Salutation: res.data.Salutation,
          Email: res.data.Email,
          Country: res.data.Country,

          DOB: res.data.DOB,
          AnniversaryDate: res.data.AnniversaryDate,
          Aadhaar: res.data.Aadhaar,
          IsDOBCalculated: res.data.IsDOBCalculated,

          compName: res.data.compName ? res.data.compName: '',
          compAddress: res.data.compAddress ? res.data.compAddress: '',
          compState: res.data.compState ? res.data.compState: '',
          compPin: res.data.compPin ? res.data.compPin: '',
          compPhone1: res.data.compPhone1 ? res.data.compPhone1: '',
          compPhone2: res.data.compPhone2 ? res.data.compPhone2: '',
          compMail: res.data.compMail ? res.data.compMail: '',
          UserPassword: res.data.UserPassword
      };
      userInfoAction(userLoginData);
      modalAction('LOGIN_MODAL', false, {mode: 'PATIENT'});
      loginStatusAction(true);
    }
  }

  const handleNewUser = () => {
    loginStatusAction(false);
    // if (hasTable) { 
    //   history.push('/login');
    // }
  }

// ----------------------- Auto Login Ends --------------------------------------------------------------------------------------------------------------------------
    return;
}


const mapStateToAuth = (state) => {
  return { compCode: state.compCode, compInfo: state.compInfo, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, cart: state.cart, siteData: state.siteData, bookingInfo: state.bookingInfo };
}
  
export default connect(mapStateToAuth, {loginStatusAction, userInfoAction, compCodeAction, loaderAction, modalAction})(Authentication);
